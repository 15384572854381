import {
  getListSiteApi,
  getListResourceApi,
  updateSiteStatusApi,
} from '@/api/action-defines/site-management';
import {
  DxDataGrid,
  DxScrolling,
  DxPager,
  DxPaging,
  DxSelection,
  DxColumn,
  DxLoadPanel,
} from 'devextreme-vue/data-grid';
import { mapGetters, mapState } from 'vuex';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import dayjs from 'dayjs';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { showToast } from '@/@core/utils/utils';

import SiteDetail from './site-detail';
import Paging from './paging/Paging.vue';
import { SiteActionsCode } from '@/@core/data/enum/menuCode';

export default {
  components: {
    DxDataGrid,
    DxScrolling,
    DxPager,
    DxPaging,
    DxSelection,
    DxColumn,
    DxLoadPanel,
    ToastificationContent,
    Paging,
    SiteDetail,
  },
  data() {
    return {
      //Datagrid
      dataSource: [],
      displayModes: [
        { text: "Display Mode 'full'", value: 'full' },
        { text: "Display Mode 'compact'", value: 'compact' },
      ],
      displayMode: 'full',
      pageSizes: [5, 10, 20, 'all'],
      showPageSizeSelector: true,
      showInfo: true,
      showNavButtons: true,
      small: false,
      outlined: true,
      striped: true,
      bordered: true,
      borderless: true,
      hover: true,
      dark: false,
      resize: true,
      reOrder: true,
      headVariant: null,
      //Site
      siteIdSelected: null,
      isSiteUpdate: true,
      isOpenDetail: false,
      isOpenModalDeactive: false,
      listCountry: [],
      listProduct: [],
      listSupplier: [],
      search: {
        SiteName: '',
        TaxCode: '',
        Supplier: [],
        CountryCode: [],
        Status: [],
        SiteClientsConnect: [],
      },
      //Pagination
      paging: {
        PageSize: 10,
        PageIndex: 1,
      },
      totalPagination: {
        TotalRecord: 0,
        TotalPage: 0,
      },
      statistics: {
        TotalRecordStatusActive: 0,
        TotalRecordStatusDeactive: 0,
      },
      //Loading
      updateStatusSiteLoading: false,
      filterBy: '',
      triggerAddSiteSuccess: false,
    };
  },
  computed: {
    ...mapState({
      $ls_Sites: (state) => state.app.resources.Sites,
    }),
    ...mapGetters({
      $ls_CurrentSiteID: 'auth/currentSiteID',
      $lsAllowOperation: 'app/allowOperation',
    }),
    displayTableSite() {
      return (
        this.triggerAddSiteSuccess ||
        this.$ls_Sites.length > 0 ||
        this.dataSource.length > 0
      );
    },
    isCanAddSite() {
      return this.$lsAllowOperation(SiteActionsCode.ADD);
    },
    isCanEditSite() {
      return this.$lsAllowOperation(SiteActionsCode.EDIT);
    },
    isCanDeactiveSite() {
      return this.$lsAllowOperation(SiteActionsCode.DEACTIVE);
    },

    isCompactMode() {
      return this.displayMode === 'compact';
    },
    configTable() {
      return [
        {
          caption: this.$t('site_management_site_name'),
          field: 'SiteName',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'site-name-cell',
          width: '20%',
        },
        {
          caption: this.$t('common_supplier'),
          field: 'PartnerName',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'partner-name-cell',
          width: '10%',
        },
        {
          caption: this.$t('common_tax_code'),
          field: 'TaxCode',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'tax-code-cell',
          width: '10%',
        },
        {
          caption: this.$t('common_address'),
          field: 'Address',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'address-cell',
          width: '15%',
        },
        {
          caption: this.$t('common_country'),
          field: 'CountryName',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'country-cell',
          width: '8%',
        },
        {
          caption: this.$t('common_status'),
          field: 'Status',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'status-cell',
          width: '12%',
        },
        {
          caption: this.$t('common_connect'),
          field: 'ClientsConnect',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'clients-connect-cell',
          width: '17%',
        },
        {
          caption: this.$t('common_actions'),
          field: 'action',
          isShow: true,
          cellTemplate: 'actions-cell',
          alignment: 'right',
        },
      ];
    },
    dataGrid() {
      return this.$refs['dataGridRef'].instance;
    },
    listStatus() {
      return [
        {
          value: 'ACTIVE',
          text: this.$t('common_acitve'),
        },
        {
          value: 'DEACTIVE',
          text: this.$t('common_deactive'),
        },
      ];
    },
  },
  async created() {
    await this.getSiteResource();
    await this.getListSite();
  },
  methods: {
    onContentReady(e) {
      e.component.option('loadPanel.enabled', false);
    },
    async getSiteResource() {
      await getListResourceApi()
        .then((res) => {
          if (res.IsSuccess) {
            this.listProduct = res.Data.ClientConnects.map((p) => {
              return {
                id: p.ClientId,
                value: p.ClientCode,
                text: p.ClientName,
              };
            });
            this.listSupplier = res.Data.Partners.map((p) => {
              return {
                value: p.PartnerId,
                text: p.PartnerName,
                PartnerURL: p.PartnerURL,
                PartnerURL2: p.PartnerURL2,
              };
            });
            this.listCountry = res.Data.Countries.map((p) => {
              return { value: p.CountryCode, text: p.Description };
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getListSite() {
      const BodyData = {
        ...this.search,
        ...this.paging,
      };
      await getListSiteApi({ BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            this.totalPagination.TotalRecord = res.Data.TotalRecord;
            this.totalPagination.TotalPage = Math.ceil(
              this.totalPagination.TotalRecord / this.paging.PageSize,
            );
            this.dataSource = res.Data.RecordsList;
            this.statistics = res.Data.Statistics;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    addSiteSuccess(siteId) {
      this.triggerAddSiteSuccess = true;
      this.selectSiteDetail(siteId);
      this.getListSite();
    },
    updateSiteSuccess() {
      this.$refs.siteDetail.isOpen = false;
      this.getListSite();
    },

    updateSiteStatusSuccess() {
      this.getListSite();
    },

    connectPartnerSuccess() {
      this.getListSite();
    },

    async deactiveSiteStatus(confirmInput) {
      const body = {
        SiteID: this.siteIdSelected,
        Status: 'DEACTIVE',
        ReasonDeactive: confirmInput,
      };
      await this.updateSiteStatus(body);
      this.$refs.confirmDeactive.isOpen = false;
    },
    async updateSiteStatus(BodyData) {
      this.updateStatusSiteLoading = true;
      await updateSiteStatusApi({ BodyData }).then((res) => {
        if (res.IsSuccess) {
          showToast('success', this.$t(res.Messages));
          this.getListSite();
        }
      });
      this.updateStatusSiteLoading = false;
    },
    searchSite() {
      this.paging.PageIndex = 1;
      this.getListSite();
    },

    openSiteAdd() {
      this.siteIdSelected = null;
      this.isSiteUpdate = false;
      this.$refs.siteDetail.isOpen = true;
    },

    selectSiteDetail(SiteId) {
      this.siteIdSelected = SiteId;
      this.isSiteUpdate = true;
      this.$refs.siteDetail.isOpen = true;
    },

    selectSiteDeative(SiteId) {
      this.siteIdSelected = SiteId;
      this.$refs.confirmDeactive.isOpen = true;
    },

    accessSite(SiteId) {
      if (SiteId !== this.$ls_CurrentSiteID) {
        this.$store.dispatch('auth/switchSite', SiteId);
      }
    },

    changePage(paging) {
      this.paging = paging;
      this.getListSite();
    },
    filterByStatus(status) {
      if (status == this.filterBy) {
        this.filterBy = '';
      } else {
        this.filterBy = status;
      }

      if (this.filterBy) {
        this.dataGrid.filter(['Status', '=', this.filterBy]);
      } else {
        this.dataGrid.clearFilter();
      }
    },
    getSiteStatusUI(Status) {
      if (Status == 'ACTIVE') {
        return {
          color: 'text-success',
          text: this.$t('common_active'),
        };
      } else {
        return {
          color: 'text-danger',
          text: this.$t('common_deactive'),
        };
      }
    },

    getClientConnectUI(client) {
      if (client.Status == 'CONNECTED') {
        return {
          text: client.ClientName,
          status: this.$t('site_management_connected'),
          color: 'light-success',
        };
      } else if (client.Status == 'NOTCONNECT') {
        return {
          text: client.ClientName,
          status: this.$t('site_management_not_connect'),
          color: 'light-warning',
        };
      } else if (client.Status == 'DISCONNECTED') {
        return {
          text: client.ClientName,
          status: this.$t('site_management_disconnected'),
          color: 'light-danger',
        };
      }
    },

    exportingExcel() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(this.$t('site_management_list'));
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'ClientsConnect') {
              let temp = gridCell.value.map((x) => {
                return x.ClientName;
              });
              excelCell.value = temp.toString();
            }
          }
        },
      })
        .then((cellRange) => {
          // header
          const headerRow = worksheet.getRow(2);
          headerRow.height = 30;
          worksheet.mergeCells(2, 1, 2, 8);

          headerRow.getCell(1).value = this.$t('site_management_list');
          headerRow.getCell(1).font = { size: 22 };
          headerRow.getCell(1).alignment = { horizontal: 'center' };
        })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], { type: 'application/octet-stream' }),
              this.$t('site_management_list') +
                ' ' +
                dayjs().format('DD-MM-YYYY') +
                '.xlsx',
            );
          });
        });
    },
  },
};

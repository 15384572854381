<template lang="">
  <div>
    <b-pagination
      class="mb-0"
      v-model="paging.PageIndex"
      pills
      hide-goto-end-buttons
      first-number
      last-number
      :per-page="1"
      :total-rows="total.TotalPage"
      :align="'right'"
    />
  </div>
</template>
<script>
export default {
  props: {
    paging: {
      type: Object,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
  },
  emits: ['change-site'],
  watch: {
    paging: {
      handler() {
        this.$emit('change-page', this.paging);
      },
      deep: true,
    },
  },
};
</script>
<style lang=""></style>

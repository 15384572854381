var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"connect-table",attrs:{"items":_vm.listClientsConnect,"fields":_vm.fields,"stacked":"md","show-empty":"","small":""},scopedSlots:_vm._u([{key:"thead-top",fn:function(data){return [_c('tr',[_c('th',{staticClass:"cell-name"},[_vm._v(_vm._s(_vm.$t('common_name')))]),_c('th',{staticClass:"cell-status"},[_vm._v(_vm._s(_vm.$t('common_status')))]),_c('th',{staticClass:"cell-expiration-date"},[_vm._v(" "+_vm._s(_vm.$t('common_expiration_date'))+" ")]),_c('th',{staticClass:"cell-token"},[_vm._v(_vm._s(_vm.$t('common_token')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('common_actions')))])]),(_vm.isCanEditSite)?_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_c('validation-observer',{ref:"clientSelect"},[_c('b-form',[_c('b-form-group',{staticClass:"mb-0"},[_c('validation-provider',{attrs:{"name":_vm.$t('site_management_product'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticStyle:{"background":"white"},attrs:{"label":"text","placeholder":_vm.$t('site_management_select_product'),"options":_vm.listProduct,"state":errors.length > 0 ? false : null},model:{value:(_vm.newConnectionClient.ClientSelect),callback:function ($$v) {_vm.$set(_vm.newConnectionClient, "ClientSelect", $$v)},expression:"newConnectionClient.ClientSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('th',{staticClass:"text-right"},[_c('validation-observer',{ref:"clientExpirationDate"},[_c('b-form',[_c('b-form-group',{staticClass:"mb-0"},[_c('validation-provider',{attrs:{"name":_vm.$t('common_expiration_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ez-date-picker',{key:_vm.datePickerKey,ref:"picker",staticStyle:{"background":"white"},attrs:{"opens":"right","locale-data":{
                      firstDay: 1,
                      format: 'dd-mm-yyyy',
                    },"timePicker":false,"timePicker24Hour":false,"showWeekNumbers":false,"showDropdowns":false,"autoApply":true,"appendToBody":false,"minDate":_vm.minDate,"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common_expiration_date')},model:{value:(_vm.newConnectionClient.ExpirationDate),callback:function ($$v) {_vm.$set(_vm.newConnectionClient, "ExpirationDate", $$v)},expression:"newConnectionClient.ExpirationDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('th',{staticClass:"text-right"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-primary","disabled":_vm.createLoading},on:{"click":_vm.createConnectionClient}},[_c('ez-icon',{attrs:{"icon":"ez_add"}}),_vm._v(" "+_vm._s(_vm.$t('common_create_token'))+" ")],1)],1)]):_vm._e()]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center flex-column p-4"},[_c('b-img',{staticClass:"mb-2",attrs:{"height":"140","src":require('@/assets/images/connect-empty.png')}}),_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.$t('site_management_connection_empty_description_1'))+" "),_c('span',{attrs:{"role":"button"}},[_c('b',[_vm._v(_vm._s(_vm.$t('common_create_token')))])])]),_c('li',[_vm._v(" "+_vm._s(_vm.$t('site_management_connection_empty_description_2'))+" ")])])],1)]},proxy:true},{key:"cell(Status)",fn:function(row){return [_c('b-badge',{attrs:{"variant":_vm.getStatusUI(row.value).color}},[_vm._v(_vm._s(_vm.getStatusUI(row.value).text))])]}},{key:"cell(Token)",fn:function(row){return [_c('span',[_vm._v(_vm._s(_vm.getTokenText(row)))])]}},{key:"cell(ExpirationDate)",fn:function(row){return [_c('ez-date-picker',{key:row.item.SiteClientConnectionID,ref:"picker",staticStyle:{"background":"white"},attrs:{"opens":"right","locale-data":{
          firstDay: 1,
          format: 'dd-mm-yyyy',
        },"placeholder":_vm.$t('common_expiration_date'),"timePicker":false,"timePicker24Hour":false,"showWeekNumbers":false,"showDropdowns":false,"autoApply":false,"appendToBody":false,"minDate":_vm.minDate,"disabled":!_vm.isCanEditSite},on:{"confirm":function (date) {
            _vm.handleUpdateExpirationDate(row.item.SiteClientConnectionID, date);
          }},model:{value:(row.value),callback:function ($$v) {_vm.$set(row, "value", $$v)},expression:"row.value"}})]}},{key:"cell(Actions)",fn:function(row){return [_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(row.item.SiteClientConnectionID != _vm.tokenShowClientId)?_c('b-button',{staticClass:"text-primary btn-icon",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function($event){_vm.tokenShowClientId = row.item.SiteClientConnectionID}}},[_c('ez-icon',{attrs:{"icon":"ez_visibility","size":"22"}})],1):_vm._e(),(row.item.SiteClientConnectionID == _vm.tokenShowClientId)?_c('b-button',{staticClass:"text-primary btn-icon",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function($event){_vm.tokenShowClientId = ''}}},[_c('ez-icon',{attrs:{"icon":"ez_visibility_off","size":"22"}})],1):_vm._e(),_c('b-button',{staticClass:"text-primary btn-icon",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function () { return _vm.handleCopyToken(row.item.Token); }}},[_c('ez-icon',{attrs:{"icon":"ez_content_copy","size":"22"}})],1),(_vm.isCanEditSite)?_c('b-button',{staticClass:"text-danger btn-icon",attrs:{"variant":"flat-danger","size":"sm"},on:{"click":function () { return _vm.openDeleteConfirmModal(row.item.SiteClientConnectionID); }}},[_c('ez-icon',{attrs:{"icon":"ez_delete_outline","size":"22"}})],1):_vm._e()],1)]}}])}),_c('ez-confirm-modal',{ref:"confirmDelete",attrs:{"variant":"delete","title":_vm.$t('common_confirm_delete_token'),"label":_vm.$t('site_management_reason_require'),"labelValidationName":_vm.$t('common_reason'),"placeholder":_vm.$t('common_reason'),"confirmLoading":_vm.deleteLoading},on:{"handle-confirm":_vm.handleDeleteConnectionClient}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import {
  createConnectionClientApi,
  deleteConnectionClientApi,
  updateConnectionClientApi,
} from '@/api/action-defines/site-management';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { showToast } from '@/@core/utils/utils';

export default {
  props: {
    siteId: {
      type: String,
      required: false,
    },
    listProduct: {
      type: Array,
      required: true,
    },
    listClientsConnect: {
      type: Array,
      required: false,
    },
    isOpenSideBar: {
      type: Boolean,
      required: true,
    },
    isCanEditSite: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['get-list-site'],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      clientsConnect: [],
      fields: [
        {
          key: 'ClientName',
          label: this.$t('common_name'),
          class: 'table-cell cell-name',
        },
        {
          key: 'Status',
          label: this.$t('common_status'),
          class: 'table-cell cell-status',
        },
        {
          key: 'ExpirationDate',
          label: this.$t('common_expiration_date'),
          class: 'table-cell cell-expiration-date',
        },
        {
          key: 'Token',
          label: this.$t('common_token'),
          class: 'table-cell cell-token',
        },

        {
          key: 'Actions',
          label: this.$t('common_actions'),
          class: 'table-cell text-right',
        },
      ],
      newConnectionClient: {
        ClientSelect: null,
        ExpirationDate: null,
      },
      isOpenModalDelete: false,
      deleteClientId: '',
      tokenShowClientId: '',
      minDate: new Date(),
      datePickerKey: null,

      //Loading
      createLoading: false,
      deleteLoading: false,
    };
  },
  watch: {
    listClientsConnect(value) {
      this.clientsConnect = value;
    },
    isOpenSideBar() {
      this.resetNewConnectionClient();
    },
  },
  methods: {
    async createConnectionClient() {
      this.$refs.clientSelect.validate().then(async (successSelect) => {
        this.$refs.clientExpirationDate.validate().then(async (success) => {
          if (successSelect) {
            if (success) {
              const BodyData = {
                SiteId: this.siteId,
                ClientID: this.newConnectionClient.ClientSelect.id,
                ExpirationDate: this.newConnectionClient.ExpirationDate,
              };
              this.createLoading = true;
              await createConnectionClientApi({ BodyData }).then((res) => {
                if (res.IsSuccess) {
                  showToast('success', this.$t(res.Messages));
                  this.$emit('get-list-site');
                  this.listClientsConnect.push(res.Data);
                  this.resetNewConnectionClient();
                }
              });
              this.createLoading = false;
            }
          }
        });
      });
    },

    async deleteConnectionClient(BodyData) {
      this.deleteLoading = true;
      await deleteConnectionClientApi({ BodyData }).then((res) => {
        if (res.IsSuccess) {
          showToast('success', this.$t(res.Messages));
          this.$emit('get-list-site');
          this.$refs.confirmDelete.isOpen = false;
          this.listClientsConnect = this.listClientsConnect.filter(
            (item) =>
              item.SiteClientConnectionID !== BodyData.SiteClientConnectionID,
          );
        }
      });
      this.deleteLoading = false;
    },

    async updateConnectionClient(BodyData) {
      await updateConnectionClientApi({ BodyData }).then((res) => {
        if (res.IsSuccess) {
          showToast('success', this.$t(res.Messages));
          this.$emit('get-list-site');
        }
      });
    },

    getStatusUI(status) {
      if (status == 'CONNECTED') {
        return {
          text: this.$t('site_management_connected'),
          color: 'light-success',
        };
      } else if (status == 'NOTCONNECT') {
        return {
          text: this.$t('site_management_not_connect'),
          color: 'light-warning',
        };
      } else if (status == 'DISCONNECTED') {
        return {
          text: this.$t('site_management_disconnected'),
          color: 'light-danger',
        };
      }
    },

    getTokenText(row) {
      if (row.item.SiteClientConnectionID == this.tokenShowClientId) {
        let text = row.value.slice(row.value.length - 24, row.value.length);
        return text;
      } else {
        let text = row.value.slice(row.value.length - 8, row.value.length);
        return '**********************' + text;
      }
    },

    handleCopyToken(copyText) {
      this.$copyText(copyText).then(
        () => {
          showToast('success', this.$t('site_management_copy_token_success'));
        },
        (e) => {
          console.log(e);
        },
      );
    },

    resetNewConnectionClient() {
      this.newConnectionClient = {
        ClientSelect: null,
        ExpirationDate: null,
      };
      this.datePickerKey = Date.now();
      this.$refs.clientSelect.reset();
      this.$refs.clientExpirationDate.reset();
    },

    openDeleteConfirmModal(clientId) {
      this.$refs.confirmDelete.isOpen = true;
      this.deleteClientId = clientId;
    },

    handleDeleteConnectionClient(confirmInput) {
      const BodyData = {
        SiteClientConnectionID: this.deleteClientId,
        ReasonDelete: confirmInput,
      };
      this.deleteConnectionClient(BodyData);
    },

    handleUpdateExpirationDate(clientID, newExpirationDate) {
      const BodyData = {
        SiteClientConnectionID: clientID,
        ExpirationDate: newExpirationDate,
      };
      this.updateConnectionClient(BodyData);
    },
  },
};

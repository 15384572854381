import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ConnectTable from './connect-table/ConnectTable.vue';
import { required } from '@validations';
import {
  detailSiteApi,
  createSiteApi,
  updateSiteApi,
  updateSiteStatusApi,
  connectPartnerApi,
  disconnectPartnerApi,
} from '@/api/action-defines/site-management';
import { showToast } from '@/@core/utils/utils';

const PartnerConnectFormInput = {
  PartnerUrl: [1, 2, 3, 4, 5, 6, 7],
  Username: [1, 2, 3, 4, 5, 6, 7],
  Password: [1, 2, 3, 4, 5, 6, 7],
  PartnerUrl2: [3, 6],
  Username2: [1, 2, 3, 5],
  Password2: [1, 5],
  TaxCode: [2, 3, 4, 6, 7],
};

export default {
  props: {
    listCountry: {
      type: Array,
      required: true,
    },
    listSupplier: {
      type: Array,
      required: true,
    },
    listProduct: {
      type: Array,
      required: true,
    },
    siteId: {
      type: String,
      required: false,
    },
    isSiteUpdate: {
      type: Boolean,
      required: true,
    },
    isCanAddSite: {
      type: Boolean,
      required: true,
    },
    isCanEditSite: {
      type: Boolean,
      required: true,
    },
    isCanDeactiveSite: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'toggle-detail',
    'add-site-success',
    'update-site-success',
    'update-sitestatus-success',
    'connect-partner-success',
    'get-list-site',
  ],
  components: {
    ValidationProvider,
    ValidationObserver,
    ConnectTable,
  },
  data() {
    return {
      isOpen: false,
      siteBody: {
        Avatar: '',
        SiteName: '',
        Email: '',
        Phone: '',
        Address: '',
        CountryCode: '',
        TaxCode: '',
        PartnerID: null,
      },
      required,
      //Password
      showPassword1: false,
      showPassword2: false,
      //Modal
      isOpenModalDeactive: false,
      isOpenModalActive: false,
      busyProcess: false,
    };
  },
  created() {},
  computed: {
    showFormPartnerConfig() {
      if (this.siteBody.PartnerID) {
        return true;
      }
      return false;
    },
    formInputPartner() {
      const partnerConnectFormInput = {
        PartnerUrl: false,
        Username: false,
        Password: false,
        PartnerUrl2: false,
        Username2: false,
        Password2: false,
        TaxCode: false,
      };
      Object.keys(partnerConnectFormInput).forEach((el) => {
        partnerConnectFormInput[el] =
          el in PartnerConnectFormInput &&
          PartnerConnectFormInput[el].length > 0 &&
          PartnerConnectFormInput[el].includes(this.siteBody.PartnerID);
      });
      return partnerConnectFormInput;
    },
    connectedPartner() {
      if (
        'PartnerConnectStatus' in this.siteBody &&
        this.siteBody.PartnerConnectStatus === 'CONNECTED'
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    openForm() {
      this.resetForm();
      if (this.siteId) {
        this.getSiteDetail(this.siteId);
      }
    },
    getListSite() {
      this.$emit('get-list-site');
    },
    connectPartnerSuccess() {
      this.$emit('connect-partner-success');
    },
    async getSiteDetail(siteId) {
      const QueryParameters = {
        siteId: siteId,
      };
      await detailSiteApi({ QueryParameters }).then((res) => {
        if (res.IsSuccess) {
          this.siteBody = res.Data;
          this.siteBody.ClientsConnect = this.siteBody.ClientsConnect.map(
            (client) => {
              return {
                ...client,
                ExpirationDate: new Date(client.ExpirationDate),
              };
            },
          );
        }
      });
    },

    handleUpdate() {
      this.$refs.siteDetailForm.validate().then((success) => {
        if (success) {
          this.siteBody.SiteID = this.siteBody.SiteId;
          this.updateSite(this.siteBody);
        }
      });
    },

    handleAdd() {
      this.$refs.siteDetailForm.validate().then((success) => {
        if (success) {
          this.addSite(this.siteBody);
        }
      });
    },
    addSite(BodyData) {
      this.busyProcess = true;
      createSiteApi({ BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            const siteId = res.Data.SiteID;
            showToast('success', this.$t(res.Messages));
            this.getSiteDetail(siteId);
            this.$emit('add-site-success', siteId);
          }
        })
        .finally(() => {
          this.busyProcess = false;
        });
    },
    updateSite(BodyData) {
      this.busyProcess = true;
      updateSiteApi({ BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            showToast('success', this.$t(res.Messages));
            this.$emit('update-site-success');
          }
        })
        .finally(() => {
          this.busyProcess = false;
        });
    },
    updateSiteStatus(BodyData) {
      this.busyProcess = true;
      updateSiteStatusApi({ BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            showToast('success', this.$t(res.Messages));
            if (BodyData.Status === 'ACTIVE') {
              this.siteBody.Status = 'ACTIVE';
              this.$refs.confirmActive.isOpen = false;
            } else if (BodyData.Status === 'DEACTIVE') {
              this.siteBody.Status = 'DEACTIVE';
              this.$refs.confirmDeactive.isOpen = false;
            }
            this.$emit('update-sitestatus-success');
          }
        })
        .finally(() => {
          this.busyProcess = false;
        });
    },
    resetForm() {
      this.siteBody = {
        Avatar: '',
        SiteName: '',
        Email: '',
        Phone: '',
        Address: '',
        CountryCode: '',
        TaxCode: '',
        PartnerID: null,
      };
      if (this.listCountry.length > 0) {
        if (this.listCountry.some((s) => s.value === 'VN')) {
          this.siteBody.CountryCode = 'VN';
        }
      }
      this.$refs.siteDetailForm.reset();
    },

    async deactiveSite(confirmInput) {
      const body = {
        SiteID: this.siteBody.SiteId,
        Status: 'DEACTIVE',
        ReasonDeactive: confirmInput,
      };
      this.updateSiteStatus(body);
    },

    async activeSite(confirmInput) {
      const body = {
        SiteID: this.siteBody.SiteId,
        Status: 'ACTIVE',
        ReasonActive: confirmInput,
      };
      this.updateSiteStatus(body);
    },
    partner_onChange(partner) {
      this.siteBody.PartnerUrl = partner.PartnerURL;
      this.siteBody.Username = '';
      this.siteBody.Password = '';
      this.siteBody.PartnerUrl2 = partner.PartnerURL2;
      this.siteBody.Username2 = '';
      this.siteBody.Password2 = '';
      this.$refs.siteDetailConnectForm.reset();
    },
    disconnectPartnerClient_onClick() {
      this.$refs.confirmDisconect.isOpen = true;
    },
    connectPartnerClient_onClick() {
      const self = this;
      this.$refs.siteDetailConnectForm.validate().then((success) => {
        if (success) {
          const bodyData = {
            SiteId: this.siteBody.SiteId,
            PartnerId: this.siteBody.PartnerID,
            TaxCode: this.siteBody.TaxCode,
            PartnerUrl: this.siteBody.PartnerUrl,
            Username: this.siteBody.Username,
            Password: this.siteBody.Password,
            PartnerUrl2: this.siteBody.PartnerUrl2,
            Username2: this.siteBody.Username2,
            Password2: this.siteBody.Password2,
          };
          this.busyProcess = true;
          connectPartnerApi({ BodyData: bodyData })
            .then((res) => {
              if (res.IsSuccess) {
                self.connectPartnerSuccess();
                showToast('success', self.$t(res.Messages));
                self.siteBody.PartnerConnectStatus = 'CONNECTED';
              }
            })
            .finally(() => {
              this.busyProcess = false;
            });
        }
      });
    },
    disconnectPartnerProcess() {
      const self = this;
      const bodyData = {
        SiteId: this.siteBody.SiteId,
      };
      this.busyProcess = true;
      disconnectPartnerApi({ BodyData: bodyData })
        .then((res) => {
          if (res.IsSuccess) {
            self.connectPartnerSuccess();
            showToast('success', self.$t(res.Messages));
            self.$refs.confirmDisconect.isOpen = false;
            self.siteBody.PartnerConnectStatus = 'DISCONNECTED';
            self.siteBody.PartnerID = null;
          }
        })
        .finally(() => {
          this.busyProcess = false;
        });
    },
  },
};

export const CREATE_SITE = 'si00-site/si01-create-site';
export const UPDATE_SITE = 'si00-site/si02-update-site';
export const GET_LIST_SITE = 'si00-site/si03-site-filter';
export const GET_LIST_RESOURCES = 'si00-site/si04-get-resources';
export const DETAIL_SITE = 'si00-site/si05-get-detail';
export const CONNECT_PARTNER = 'si00-site/si06-connect-partner';
export const DISCONNECT_PARTNER = 'si00-site/si07-disconnect-partner';
export const UPDATE_SITE_STATUS = 'si00-site/si10-update-sitestatus';
export const CREATE_CONNECTION_CLIENT = 'sc00-siteclient/sc01-create-token';
export const UPDATE_CONNECTION_CLIENT = 'sc00-siteclient/sc02-update-token';
export const DELETE_CONNECTION_CLIENT = 'sc00-siteclient/sc03-delete-token';
import { $GET, $POST } from '@/api/gateway/gatewayMethods';
import {
  GET_LIST_SITE,
  CREATE_SITE,
  UPDATE_SITE,
  DETAIL_SITE,
  GET_LIST_RESOURCES,
  CONNECT_PARTNER, 
  DISCONNECT_PARTNER,
  UPDATE_SITE_STATUS,
  CREATE_CONNECTION_CLIENT,
  UPDATE_CONNECTION_CLIENT,
  DELETE_CONNECTION_CLIENT,
} from '@/api/endpoint-defines/site-management';

export const getListSiteApi = ({ BodyData }) =>
  $POST({ EndPoint: GET_LIST_SITE, BodyData });

export const getListResourceApi = () =>
  $GET({ EndPoint: GET_LIST_RESOURCES, QueryParameters: null });

export const createSiteApi = ({ BodyData }) =>
  $POST({ EndPoint: CREATE_SITE, BodyData });

export const updateSiteApi = ({ BodyData }) =>
  $POST({ EndPoint: UPDATE_SITE, BodyData });

export const detailSiteApi = ({ QueryParameters }) =>
  $GET({ EndPoint: DETAIL_SITE, QueryParameters });

export const connectPartnerApi = ({ BodyData }) =>
  $POST({ EndPoint: CONNECT_PARTNER, BodyData });

export const disconnectPartnerApi = ({ BodyData }) =>
  $POST({ EndPoint: DISCONNECT_PARTNER, BodyData });

export const createConnectionClientApi = ({ BodyData }) =>
  $POST({ EndPoint: CREATE_CONNECTION_CLIENT, BodyData });

export const updateConnectionClientApi = ({ BodyData }) =>
  $POST({ EndPoint: UPDATE_CONNECTION_CLIENT, BodyData });

export const deleteConnectionClientApi = ({ BodyData }) =>
  $POST({ EndPoint: DELETE_CONNECTION_CLIENT, BodyData });

export const updateSiteStatusApi = ({ BodyData }) =>
  $POST({ EndPoint: UPDATE_SITE_STATUS, BodyData });
